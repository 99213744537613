<template>
  <div class="shop">
    <div class="shop__filter" v-bind:class="{ shop__filter_disabled: !store.enableShop }">
      <div class="button shadow-box" v-on:click="store.showFilter = !store.showFilter">
        <img alt="Header Image" src="@/assets/images/icons/filter_icon.png">
        Filter 
      </div>

      <div class="shop__filter_box_outer" v-bind:class="{ shop__filter_box_outer_active: store.showFilter }">
        <div class="shop__filter_box shadow-box">
          <div class="shop__filter_box_flag"></div>
          <h4>Sortieren:</h4>
          <ul>
            <li v-bind:class="{ filter_active: activeSortOf('price_lh') }" class="shop__filter_sort" v-on:click="selectSort('price_lh')">
              <b>Preis ⇗</b>
            </li>
             <li v-bind:class="{ filter_active: activeSortOf('price_hl') }" class="shop__filter_sort" v-on:click="selectSort('price_hl')">
              <b>Preis ⇘</b>
            </li>
            <li v-bind:class="{ filter_active: activeSortOf('name_az') }" class="shop__filter_sort" v-on:click="selectSort('name_az')">
              <b>Name</b> A-Z
            </li>
            <li v-bind:class="{ filter_active: activeSortOf('name_za') }" class="shop__filter_sort" v-on:click="selectSort('name_za')">
              <b>Name</b> Z-A
            </li>
            <li v-bind:class="{ filter_active: activeSortOf('age_lh') }" class="shop__filter_sort" v-on:click="selectSort('age_lh')">
              <b>Neueste ⇗</b>
            </li>
             <li v-bind:class="{ filter_active: activeSortOf('age_hl') }" class="shop__filter_sort" v-on:click="selectSort('age_hl')">
              <b>Älteste ⇘</b>
            </li>
          </ul>

          <h4>Suche:</h4>
          <ul>
            <li>
              <input v-model="store.searchModel.text" placeholder="Bandname"/>
            </li>
          </ul>

          <h4>Typ:</h4>
          <ul>
            <li v-bind:class="{ filter_active: activeTypeOf('unisexshirts') }" v-on:click="selctType('unisexshirts')">
              Unisex Shirts
            </li>
            <li v-bind:class="{ filter_active: activeTypeOf('formfittedshirts') }" v-on:click="selctType('formfittedshirts')">
              Form Fitted Shirts
            </li>
            <li v-bind:class="{ filter_active: activeTypeOf('unisexsweats') }" v-on:click="selctType('unisexsweats')">
              Unisex Sweater
            </li>
            <li v-bind:class="{ filter_active: activeTypeOf('formfittedsweats') }" v-on:click="selctType('formfittedsweats')">
              Form Fitted Sweater
            </li>
            <li v-bind:class="{ filter_active: activeTypeOf('specials') }" v-on:click="selctType('specials')">
              Specials
            </li>
            <li v-bind:class="{ filter_active: activeTypeOf('bags') }" v-on:click="selctType('bags')">
              Bags
            </li>
          </ul>
          <h4>Groesse:</h4>
          <ul>
            <li v-bind:class="{ filter_active: activeSizeOf('xs') }" v-on:click="selctSize('xs')">
              XS
            </li>
            <li v-bind:class="{ filter_active: activeSizeOf('s') }" v-on:click="selctSize('s')">
              S
            </li>
            <li v-bind:class="{ filter_active: activeSizeOf('m') }" v-on:click="selctSize('m')">
              M
            </li>
            <li v-bind:class="{ filter_active: activeSizeOf('l') }" v-on:click="selctSize('l')">
              L
            </li>
            <li v-bind:class="{ filter_active: activeSizeOf('xl') }" v-on:click="selctSize('xl')">
              XL
            </li>
            <li v-bind:class="{ filter_active: activeSizeOf('2xl') }" v-on:click="selctSize('2xl')">
              XXL
            </li>
            <li v-bind:class="{ filter_active: activeSizeOf('youthm') }" v-on:click="selctSize('youthm')">
              Youth M
            </li>
            <li v-bind:class="{ filter_active: activeSizeOf('youthl') }" v-on:click="selctSize('youthl')">
              Youth L
            </li>
          </ul>
          <h4>Zustand:</h4>
          <ul>
            <li v-bind:class="{ filter_active: activeConditionOf('neu') }" v-on:click="selctCondition('neu')">
              Neu
            </li>
            <li v-bind:class="{ filter_active: activeConditionOf('wieneu') }" v-on:click="selctCondition('wieneu')">
              Wie Neu
            </li>
            <li v-bind:class="{ filter_active: activeConditionOf('gebraucht') }" v-on:click="selctCondition('gebraucht')">
              Gebraucht
            </li>
            <!--
            <li v-bind:class="{ filter_active: activeConditionOf('punkrock') }" v-on:click="selctCondition('punkrock')">
              Punkrock
            </li>
            -->
          </ul>
        </div>
      </div>
    </div>

    <h4 v-if="filteredProducts.length == 0" class="shop__no_products">Es wurden leider keine passenden Produkte gefunden. Verändere deine Suchkretieren.</h4>

    <div class="shop__products">
      <div class="shop__products_inner">
        <div v-if="!store.enableShop" class="shop__disabled_overlay">
      <div class="shop__disabled_inner">
        <h3>Wir machen Urlaub!</h3>
        <img alt="Corona Icon" src="@/assets/images/icons/holiday_icon.png">  
        <p>{{store.disableShopInfo}}<br>
Die Wiedereröffnung werden wir dann über Facebook, Instagram und co ankündigen.<br><br>
In der nächsten Zeit findet ihr uns außerdem hier: <router-link id="sellers" to="/sellers">Aktuelle Verkaufsstände</router-link><br><br>

Schaut also einfach später noch einmal vorbei!
<br><br>
🖤
</p>
      </div>
    </div>
        <template  v-for="(product, index) in filteredProducts">
          <div v-on:click="showProductDetail(product.artikelnummer)" :key="product.id" v-if="index >= store.currentPageIndex * itemsPerPage && index <= (store.currentPageIndex + 1) * itemsPerPage - 1" class="shop__product">
            <ShirtPreviewBox  class="shop__product_tile" :productData=product></ShirtPreviewBox>
          </div>
        </template>
      </div>
    </div>
    <paginate v-bind:class="{ shop__filter_disabled: !store.enableShop }" v-if="paginationPages"
      v-model="store.page"
      :page-count="paginationPages"
      :click-handler="clickCallback"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'pagination'"
      :page-class="'page-item'">
    </paginate>
  </div>
</template>

<script>
import ShirtPreviewBox from "@/components/ShirtPreviewBox.vue";
import Paginate from 'vuejs-paginate'

import store from '@/store/store.js';

export default {
  name: 'Shop',
  metaInfo: {
    title: 'Second Bandshirt - Shop',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  components: {
    ShirtPreviewBox,
    Paginate
  },
  data() {
    return {
      store
    };
  },
  computed: {
    paginationPages: function () {
      var pagesCount = Math.round(this.filteredProducts.length / this.itemsPerPage);
      return pagesCount;
    },
    itemsPerPage: function () {
      var items = 60;
      /*
      if(window.innerWidth < 612){
        items = 4;
      }
      if(window.innerWidth < 1024){
        items = 8;
      }
      */
      return items;
    },
    filteredProducts: function () {
      var filteredProductsData = this.store.productsData;

      //free text
      if (store.searchModel.text != ""){
        this.resetPageIndex();
        filteredProductsData = this.store.productsData.filter(c => c.produktName.toLowerCase().indexOf(store.searchModel.text.toLowerCase()) > -1);
      }
      //size
      if(store.sizeArray.length > 0)
        filteredProductsData = this.filterCategoryItems(filteredProductsData, store.sizeArray, "size");
      //condition
      if(store.conditionArray.length > 0)
        filteredProductsData = this.filterCategoryItems(filteredProductsData, store.conditionArray, "condition");
      //type
      if(store.typeArray.length > 0)
        filteredProductsData = this.filterCategoryItems(filteredProductsData, store.typeArray, "type");
      
       //type
      if(store.sortArray.length > 0)
        filteredProductsData = this.filterCategoryItems(filteredProductsData, store.sortArray, store.sortArray[0]);
      

      return filteredProductsData;
    }
  },
  methods: {
    clickCallback (pageNum) {
      store.currentPageIndex = pageNum - 1;
      window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    },
    selctSize(sizeType){    
      this.resetPageIndex();

      if(store.sizeArray.indexOf(sizeType) === -1){
        store.sizeArray.push(sizeType);
      }else{
        store.sizeArray.splice(store.sizeArray.indexOf(sizeType), 1);
      }
    },
    selectSort(sortType){    
      this.resetPageIndex();
      if(store.sortArray.indexOf(sortType) === -1){
        store.sortArray = new Array();
        store.sortArray.push(sortType);
      }else{
        store.sortArray.splice(store.sortArray.indexOf(sortType), 1);
      }
    },
    activeSizeOf(sizeType){
      var exists = false;
      if (store.sizeArray.indexOf(sizeType) === -1) {
        exists = false;
      }
      else {
        exists = true;
      }
      return exists;
    },
    selctType(type){
      this.resetPageIndex();
      if(store.typeArray.indexOf(type) === -1){
        store.typeArray.push(type);
      }else{
        store.typeArray.splice(store.typeArray.indexOf(type), 1);
      }
    },
    activeTypeOf(type){
      var exists = false;
      if (store.typeArray.indexOf(type) === -1) {
        exists = false;
      }
      else {
        exists = true;
      }
      return exists;
    },
    activeSortOf(type){
      var exists = false;
      if (store.sortArray.indexOf(type) === -1) {
        exists = false;
      }
      else {
        exists = true;
      }
      return exists;
    },
    selctCondition(condition){
      this.resetPageIndex();
      if(store.conditionArray.indexOf(condition) === -1){
        store.conditionArray.push(condition);
      }else{
        store.conditionArray.splice(store.conditionArray.indexOf(condition), 1);
      }
    },
    activeConditionOf(condition){
      var exists = false;
      if (store.conditionArray.indexOf(condition) === -1) {
        exists = false;
      }
      else {
        exists = true;
      }
      return exists;
    },
    showProductDetail(productIndex){
      this.store.selectedProductIndex = productIndex;
      this.$router.push({ path: 'productDetail', query: { shirtId: productIndex } })
    },
    filterCategoryItems(filteredProductsData, productsArray, type){
      
      var localProductsArray = [];
      for(var i = 0; i < productsArray.length; i++){
        var searchWord = productsArray[i];

        var filteredItems = [];
         if(type == "size")
         filteredItems = filteredProductsData.filter(c => c.grosse.toLowerCase() == searchWord);
        if(type == "condition")
        filteredItems = filteredProductsData.filter(c => c.zustand.toLowerCase() == searchWord);
        if(type == "type")
        filteredItems = filteredProductsData.filter(c => c.kategorie.toLowerCase() == searchWord);
        if(type == "name_az"){
           var filteredArrayData =  JSON.parse(JSON.stringify(filteredProductsData));
            filteredArrayData.sort(function(a, b) {
              var nameA = a.produktName.toUpperCase(); // Groß-/Kleinschreibung ignorieren
              var nameB = b.produktName.toUpperCase(); // Groß-/Kleinschreibung ignorieren
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
            filteredItems = filteredArrayData;
        }
        if(type == "name_za"){
          var filteredArrayData2 =  JSON.parse(JSON.stringify(filteredProductsData));
          filteredArrayData2.sort(function(a, b) {
            var nameA = a.produktName.toUpperCase(); // Groß-/Kleinschreibung ignorieren
            var nameB = b.produktName.toUpperCase(); // Groß-/Kleinschreibung ignorieren
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0;
          });
          filteredItems = filteredArrayData2;
        }
        if(type == "price_hl"){
          var filteredArrayData3 =  JSON.parse(JSON.stringify(filteredProductsData));
          filteredArrayData3.sort(function(a, b) {
            var preisA = parseFloat(a.preis);
            var preisB = parseFloat(b.preis);
            if (preisA < preisB) {
              return 1;
            }
            if (preisA > preisB) {
              return -1;
            }
            return 0;
          });
          filteredItems = filteredArrayData3;
        }
        if(type == "price_lh"){
          var filteredArrayData4 =  JSON.parse(JSON.stringify(filteredProductsData));
          filteredArrayData4.sort(function(a, b) {
            var preisA = parseFloat(a.preis);
            var preisB = parseFloat(b.preis);
            if (preisA < preisB) {
              return -1;
            }
            if (preisA > preisB) {
              return 1;
            }
            return 0;
          });
          filteredItems = filteredArrayData4;
        }
        if(type == "age_lh"){
          var filteredArrayData5 =  JSON.parse(JSON.stringify(filteredProductsData));
          filteredItems = filteredArrayData5;
        }
        if(type == "age_hl"){
          var filteredArrayData6 =  JSON.parse(JSON.stringify(filteredProductsData));
          filteredArrayData6.reverse();
          filteredItems = filteredArrayData6;
        }
        
        Array.prototype.push.apply(localProductsArray,filteredItems);          
      }

      

      filteredProductsData = localProductsArray;
      
      return filteredProductsData;
    },
    resetPageIndex(){
      store.page = 0;
      store.currentPageIndex = 0;
    }
  }
}
</script>

<style lang="scss">
  .shop{
    margin-bottom: 50px;
    padding-top: $header_height;
    &__no_products{
      text-align: center;
      color: black;
      padding: 16px;
    }
    &__product_tile{
      cursor: pointer;
    }
    &__products{
      padding-top: 40px;
      text-align: center;
      @media only screen and (min-width: 1024px){ 
         min-height: 600px;
      }
      &_inner{
        display: block;
        position: relative;
      }
    }
    &__product{
      display: inline-block;
      width: 50%;
      @media only screen and (min-width: 612px){ 
        width: 25%;  
      }
      @media only screen and (min-width: 1024px){ 
        width: 20%;  
      }
    }
    &__product_tile{
      .shirt-box__inner {
        margin-left: 8px;
      }
    }
    &__filter{
      position: relative;
      .button{
        position: absolute;
        right: 16px;
        top: 56px;
        z-index: 9;
        cursor: pointer;
         @media only screen and (min-width: 1024px){ 
          top: 82px;
        }
      }

      &_sort{
        height: 25px!important;
        font-size: 12px!important;
        line-height: 26px!important;
        width: 70px!important;
        border: 2px solid white!important;
        position: relative;
        font-weight: normal;
        b{
          font-weight: normal;
          font-size: 15px!important;
          line-height: 26px!important;
        }
        img{
          width: 18px;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }
    &__filter_disabled{
      opacity: 0.2;
      pointer-events: none;
    }
    &__filter_box_outer{
      overflow: hidden;
      height: 100px;
      transition: all 0.3s;
    }
    &__filter_box_outer_active{
      height: 980px;
      @media only screen and (min-width: 320px){ 
        height: 1020px;
      }
      @media only screen and (min-width: 350px){ 
        height: 1020px;
      }
      @media only screen and (min-width: 380px){ 
        height: 850px;
      }
      @media only screen and (min-width: 440px){ 
        height: 850px;
      }
       @media only screen and (min-width: 510px){ 
        height: 730px;
      }
      @media only screen and (min-width: 650px){ 
        height: 680px;
      }
      @media only screen and (min-width: 920px){ 
        height: 610px;
      }
    }
    &__filter_box_flag{
      width: 15px;
      height: 15px;
      background-color: $color-primary;
      transform: rotate(45deg);
      position: absolute;
      right: 93px;
      top: -5px;
    }
    &__filter_box{
        position: relative;
        margin-top: 115px;
        width: 100%;
        background-color: $color-primary;
        color: $color-white;
        padding-left: 16px;
        padding-top: 0px;
        padding-bottom: 16px;
        @media only screen and (min-width: 1024px){ 
          margin-top: 145px;
        }
        h4 {
          padding-top: 16px;
          color: white;
        }
        input{
          background-color: transparent;
          color: white;
          font-family: 'Bebas Neue', sans-serif;
          font-size: 22px;
          line-height: 42px;
          text-transform: uppercase;
          font-weight: normal;
          text-align: center;
          outline: none;
          border: none;
        }
        input::placeholder {
          color: white;
          opacity: 0.8;
        }
        ul{
          list-style: none;
          margin: 0;
          padding: 0;
          text-align: left;
          li{
            display: inline-block;
            width: auto;
            padding-left: 16px;
            padding-right: 16px;
            min-width: 20px;
            height: 40px;
            border: 3px white solid;

            font-family: 'Bebas Neue', sans-serif;
            font-size: 22px;
            line-height: 42px;
            text-transform: uppercase;
            margin: 5px;
            margin-left: 0;
            margin-right: 10px;
            font-weight: normal;
            text-align: center;
            transition: all 0.3s;
            cursor: pointer;
          }
        }
    }
  }

  .filter_active{
    background-color: white;
    border-color: white;
    color: black;
  }

  .disabled{
    opacity: 0.2;
  }
</style>